import { useContext, useEffect, useRef } from "react";
import Tagify from "@yaireo/tagify";
import "@yaireo/tagify/dist/tagify.css";
import "./styles/ChatInput.css";
import { CircularProgress } from "@mui/material";
import { list_users } from "@/services/Blar/Company";
import { Context } from "@/ContextProvider";
import debounce from "lodash.debounce";
import { search_node } from "@/services/Blar/Repo_graph";
import { useTagify } from "../../context/TagifyContext";

const TagifyComponent = ({
  sendMessage,
  loading,
  setLoading,
  selectedChatRoom,
  selectedAgent,
  selectedNode,
  handleSendTag,
}) => {
  const inputRef = useRef(null);
  const tagifyRef = useTagify();
  const { showMessage, companyName } = useContext(Context);

  const mapUsersToTagify = (users) => {
    if (users.length === 0) return [];
    return users.map((user) => ({
      id: user.id,
      value: user.id,
      email: user.email,
      first_name: user.first_name,
      last_name: user.last_name,
      color: user.color,
      secondaryColor: user.secondary_color,
      tagType: "user",
    }));
  };

  const mapNodesToTagify = (nodes) => {
    return nodes.map((node) => ({
      value: node.name,
      id: node.node_id,
      path: node.path?.replace("temp.repos.", ""),
      type: node.type,
      tagType: "node",
    }));
  };

  useEffect(() => {
    if (tagifyRef.current) {
      tagifyRef.current.setDisabled(loading);
    }
    // eslint-disable-next-line
  }, [loading]);

  const suggestionItemTemplate = (item) => {
    const isSelected = tagifyRef.current?.isTagDuplicate(item.id);
    const attributes = tagifyRef.current?.getAttributes(item);
    const itemClasses = [
      tagifyRef.current?.settings.classNames.dropdownItem,
      isSelected
        ? tagifyRef.current?.settings.classNames.dropdownItemSelected
        : "",
      item.class || "",
    ]
      .join(" ")
      .trim();

    const getInitials = (firstName, lastName, email) => {
      if (firstName && lastName) {
        return `${firstName[0] || ""}${lastName[0] || ""}`.toUpperCase();
      } else if (email) {
        const emailName = email.split("@")[0];
        const parts = emailName.split(".");
        return parts
          .map((part) => part[0])
          .join("")
          .toUpperCase();
      }
      return "U";
    };

    // Determine avatar background
    const avatarStyle = {
      background: `linear-gradient(-45deg, #${item.color}, #${item.secondaryColor})`,
      color: "#ffffff",
      width: "36px",
      height: "36px",
      display: "flex",
      "justify-content": "center",
      "border-radius": "50%",
      "font-size": "14px",
      "text-align": "center",
      "font-weight": "bold",
      "line-height": "8px",
    };

    const styleToString = (styleObj) => {
      return Object.entries(styleObj)
        .map(([key, value]) => `${key}: ${value}`)
        .join("; ");
    };

    // Determine the template based on the prefix
    if (item.tagType === "user") {
      // User template with icon
      return `
        <div 
          ${attributes || ""}
          class="tagify__dropdown__item ${itemClasses}"
          tabindex="0"
          role="option">
          <div class='tagify__dropdown__item__avatar-wrap' >
            <div style="${styleToString(avatarStyle)}">
              ${getInitials(item.first_name, item.last_name, item.email)}
            </div>
          </div>
          <strong>${item.first_name ? item.first_name : "John"} ${
        item.last_name ? item.last_name : "Doe"
      }</strong>
          <br>
          <span>${item.email}</span>
        </div>
      `;
    } else if (item.tagType === "node") {
      // Node template with icon
      const iconSrc = item.type.includes("CLASS")
        ? "/icons/data_object.png"
        : item.type.includes("FILE")
        ? "/icons/insert_drive.png"
        : item.type.includes("FUNCTION")
        ? "/icons/function-icon-white.png"
        : "/icons/file-icon.png";

      return `

        <div 
          ${attributes || ""}
          class="${itemClasses}"
          tabindex="0"
          role="option">
          <div class='tagify__dropdown__item__avatar-wrap'>
            <img onerror="this.style.visibility='hidden'" src="${iconSrc}" alt="Node Icon" style="width: 22px; height: 22px; margin-right: 8px;">
          </div>
          <strong>${item.value}</strong>
          <br>
          <span>${item.path || "No path available"}</span>
        </div>
      `;
    }
  };

  const onMessageSend = async () => {
    const tagify = tagifyRef.current;
    const tags = tagify.value;
    const input = tagify.DOM.input.textContent;

    const message = input;
    const uniqueNodeIds = Array.from(
      new Map(
        tags
          .filter((tag) => tag.tagType === "node")
          .map((tag) => [tag.id, tag.id]) // Map tag.id to itself
      ).keys() // Use .keys() to get the unique tag ids
    );

    const userTags = Array.from(
      new Map(
        tags
          .filter((tag) => tag.tagType === "user")
          .map((tag) => [tag.value, tag])
      ).values()
    );

    if (userTags.length > 0) {
      try {
        setLoading(true);
        await handleSendTag(tagify.state.lastOriginalValueReported, userTags);
        tagify.removeAllTags();
      } catch (error) {
        showMessage("error", "Failed to send message users");
      } finally {
        setLoading(false);
      }

      return;
    }
    if (message) {
      try {
        sendMessage(
          message,
          uniqueNodeIds,
          tagify.state.lastOriginalValueReported
        );
        tagify.removeAllTags();
      } catch (error) {}
    }
  };

  useEffect(
    () => {
      if (inputRef.current && !tagifyRef.current) {
        const tagify = new Tagify(inputRef.current, {
          mode: "mix",
          pattern: /@|\\/, // Updated pattern to include "\"
          tagTextProp: "email",
          enforceWhitelist: false,
          keepInvalidTags: true,
          placeholder: "Message Blar, @mention a user, or \\select a node",
          mixTagsInterpolator: ["<blar-tag>", "</blar-tag>"],
          templates: {
            dropdownItem: suggestionItemTemplate,
          },
          trim: false,
          editTags: false,
          addTagOnBlur: false,
          onChangeAfterBlur: false,
          addTagOn: ["enter", "tab"],
          searchKeys: ["email", "first_name", "last_name"],
          dropdown: {
            enabled: 1,
            mapValueTo: "email",
            highlightFirst: true,
            className: "chat-input",
            placeAbove: true,
            position: "all",
          },
        });
        tagify.setDisabled(loading);

        tagifyRef.current = tagify;

        tagify.on("keydown", (e) => {
          if (
            e.detail.event.key === "Enter" &&
            !e.detail.event.shiftKey &&
            !tagify.state.tag &&
            (tagify.suggestedListItems.length === 0 ||
              !tagify.state.dropdown.visible)
          ) {
            onMessageSend(tagify);
          }
        });

        tagify.DOM.input.addEventListener("keydown", function (e) {
          if (
            e.key === "Enter" &&
            !e.shiftKey &&
            !tagify.state.tag &&
            (tagify.suggestedListItems.length === 0 ||
              !tagify.state.dropdown.visible)
          )
            e.preventDefault();
        });

        const debouncedFetchUsers = debounce(async (searchValue) => {
          try {
            tagify.loading(true);
            const usersResult = (
              await list_users(
                { limit: 10, offset: 0 },
                { search: searchValue }
              )
            ).data.results;
            tagify.whitelist = mapUsersToTagify(usersResult);
          } catch (error) {
            showMessage("error", "Failed to fetch users");
          } finally {
            tagify.loading(false);
            tagify.dropdown.toggle(true);
          }
        }, 300);

        const debouncedFetchNodes = debounce(async (searchValue) => {
          try {
            tagify.loading(true);
            const response = await search_node(searchValue || "");
            const nodesResult = response.data;
            tagify.whitelist = mapNodesToTagify(nodesResult);
          } catch (error) {
            showMessage("error", "Failed to fetch nodes");
          } finally {
            tagify.loading(false);
            tagify.dropdown.toggle(true);
          }
        }, 300);

        tagify.on("input", async (e) => {
          const prefix = e.detail.prefix;
          tagify.whitelist = [];
          if (prefix) {
            if (prefix.trim() === "@" && e.detail.value.length > 1) {
              if (e.detail.value.includes(" ")) {
                tagify.dropdown.hide();
                return;
              }
              if (companyName !== "Playground Company") {
                await debouncedFetchUsers(e.detail.value);
              }
              if (e.detail.value.length > 1)
                tagify.dropdown.show(e.detail.value);
            } else if (prefix.trim() === "\\" && e.detail.value.length > 1) {
              await debouncedFetchNodes(e.detail.value);
              if (e.detail.value.length > 1)
                tagify.dropdown.show(e.detail.value);
            }
          }
        });
      }
      return () => {
        if (tagifyRef.current) {
          tagifyRef.current.destroy();
          tagifyRef.current = null;
        }
      };
    },

    // eslint-disable-next-line
    [inputRef, selectedChatRoom, selectedAgent]
  );

  return (
    <>
      <textarea
        className="chat-input"
        style={{ width: "100%", maxHeight: "20vh", overflowY: "auto" }}
        ref={inputRef}
        onChange={() => {}} // This is needed to prevent React warnings
        name="mix"
      />
      {loading ? (
        <CircularProgress />
      ) : (
        <img
          src="/icons/send.svg"
          alt="Send"
          id="send-button"
          onClick={() => onMessageSend()}
          style={{
            cursor: "pointer",
            height: "20px",
            marginLeft: "10px",
          }}
        />
      )}
    </>
  );
};

export default TagifyComponent;
