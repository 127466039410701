import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import {
  check_integration_exists,
  github_list_repo_branches,
  github_list_repos,
  github_repository_info,
} from "@/services/Blar/Integrations";
import { useContext, useEffect, useState } from "react";

import { AxiosResponse } from "axios";
import BaseModal from "@/components/Modal/BaseModal";
import { Context } from "../../../../../ContextProvider";
import { IntegrationType } from "@/interfaces/IIntegration";
import LoadingButton from "@mui/lab/LoadingButton";
import React from "react";
import { Repository } from "@/interfaces/IBackendOutputs";
import { create_repo } from "@/services/Blar/Repo_graph";

type AddIntegrationModalProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  setCreatedObject: (createdObject: number) => void;
};

const AddRepoModal = ({
  open,
  setOpen,
  setCreatedObject,
}: AddIntegrationModalProps) => {
  const [repositories, setRepositories] = React.useState<Repository[]>([]);
  const [isGHConnected, setIsGHConnected] = React.useState(false);

  const [branches, setBranches] = React.useState<string[]>([]);
  const [selectedRepository, setSelectedRepository] = React.useState<number>(-1);
  const [selectedBranch, setSelectedBranch] = React.useState<number>(-1);
  const [loading, setLoading] = useState(false);
  const [loadingRepos, setLoadingRepos] = React.useState(false);
  const [isRepositoryToBig, setIsRepositoryToBig] = React.useState(false);
  const [repositoryPage, setRepositoryPage] = React.useState(1);
  const [branchesPage, setBranchesPage] = React.useState(1);
  const [hasMoreRepos, setHasMoreRepos] = React.useState(true);
  const [hasMoreBranches, setHasMoreBranches] = React.useState(true);
  const { showMessage } = useContext(Context);

  const handleBranchChange = (event: SelectChangeEvent<number | null>) => {
    if (event.target.value === null || typeof event.target.value === "string") {
      return;
    }
    setSelectedBranch(event.target.value!);
  };

  const fetchNextDataRepository = async () => {
    try {
      setLoadingRepos(true);
      const repositoriesResponse: AxiosResponse = await github_list_repos(repositoryPage);
      if (repositoriesResponse.status === 200) {
        setRepositories((prev) => [...prev, ...repositoriesResponse.data.results]);
        setRepositoryPage(repositoriesResponse.data.page + 1);
        setHasMoreRepos(repositoriesResponse.data.total_count > repositories.length);
      }
    } catch (error) {
      showMessage("error", "Failed to get repositories");
    } finally {
      setLoadingRepos(false);
    }
  }

  const fetchNextDataBranches = async (repo: string, page: number) => {
    try {
      setLoadingRepos(true);
      const branchesResponse: AxiosResponse = await github_list_repo_branches(repo, page);
      if (branchesResponse.status === 200) {
        setBranches((prev) => [...prev, ...branchesResponse.data.results]);
        setBranchesPage(branchesResponse.data.page + 1);
        setHasMoreBranches(branchesResponse.data.total_count > branches.length);
      }
    } catch (error) {
      showMessage("error", "Failed to get branches");
    } finally {
      setLoadingRepos(false);
    }
  }

  useEffect(() => {
    // get repository branches
    setBranchesPage(1);
    setBranches([]);
    if (selectedRepository !== -1) {
      fetchNextDataBranches(repositories[selectedRepository].fullName, 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [repositories, selectedRepository]);

  useEffect(() => {
    const checkGHIntegrationExists = async (): Promise<boolean> => {
      try {
        setLoading(true);

        const res = await check_integration_exists({
          source: IntegrationType.GITHUB,
        });
        setIsGHConnected(res.data);
        return res.data;
      } catch (error) {
        showMessage("error", "Failed to get Sentry integration");
      } finally {
        setLoading(false);
      }

      return false;
    };
    if (isGHConnected === false) {
      checkGHIntegrationExists();
    }

    if (isGHConnected) {
      fetchNextDataRepository();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGHConnected]);

  const handleClose = () => {
    setSelectedRepository(-1);
    setSelectedBranch(-1);
    setOpen(false);
  };

  const handleRepositoryChange = async (event: SelectChangeEvent<number | null>) => {
    const value = parseInt(event.target.value as string, 10);
    if (isNaN(value)) {
      return;
    }

    setSelectedRepository(value);
    const repositoryName = repositories[value].fullName;
    const org = repositoryName.split("/")[0];
    const repo = repositoryName.split("/")[1];
    try {
      const repositoryInfoRequest = await github_repository_info(org, repo);
      const repositoryInfo = repositoryInfoRequest.data;
      const size = repositoryInfo.size;
      // check if the repository is too big, 2GB
      if (size > 2000000) {
        setIsRepositoryToBig(true);
      } else {
        setIsRepositoryToBig(false);
      }
    } catch (error) { }
  };

  const handleAddRepository = async () => {
    if (selectedRepository !== -1 && selectedBranch !== -1) {
      try {
        setLoading(true);
        const addRepoParams = {
          github_url: repositories[selectedRepository].url,
          main_branch: branches[selectedBranch],
        };
        await create_repo(addRepoParams);
        setSelectedRepository(-1);
        setCreatedObject(Date.now());
        handleClose();
      } catch (error) {
        showMessage("error", "Failed to add repository");
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <BaseModal
      open={open}
      onClose={handleClose}
      title="Integrate New Repo"
      style={{ width: "50%" }}
    >
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        {isGHConnected ? (
          <>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginBottom: "20px",
              }}
            >
              <FormControl fullWidth>
                <InputLabel id="repository_select">
                  Select a repository
                </InputLabel>
                <Select
                  id="repository_select"
                  color="secondary"
                  value={selectedRepository}
                  label="Repository"
                  placeholder="Select a repository"
                  onChange={handleRepositoryChange}
                  MenuProps={{
                    PaperProps: {
                      onScroll: (event: React.UIEvent<HTMLElement>) => {
                        const bottom =
                          event.currentTarget.scrollHeight ===
                          event.currentTarget.scrollTop + event.currentTarget.clientHeight;
                        if (bottom && hasMoreRepos && !loadingRepos) {
                          fetchNextDataRepository();
                        }
                      },
                      style: { maxHeight: 300, overflowY: "auto" },
                    },
                  }}
                >
                  {repositories.map((repo: Repository, index: number) => (
                    <MenuItem key={index} value={index}>
                      {repo.fullName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {isRepositoryToBig && (
                <Typography variant="body1" color="error">
                  Currently we don't support repositories bigger than 2GB. PLease
                  select another one
                </Typography>
              )}
            </Box>

            {selectedRepository >= 0 && (
              <FormControl
                fullWidth
                disabled={!isGHConnected}
              >
                <InputLabel id="branch_select">Select a branch</InputLabel>
                <Select
                  id="branch_select"
                  value={selectedBranch}
                  label="Branches"
                  placeholder="Select a branch"
                  onChange={handleBranchChange}
                  MenuProps={{
                    PaperProps: {
                      onScroll: (event: React.UIEvent<HTMLElement>) => {
                        const bottom =
                          event.currentTarget.scrollHeight ===
                          event.currentTarget.scrollTop + event.currentTarget.clientHeight;
                        if (bottom && hasMoreBranches && !loadingRepos) {
                          fetchNextDataBranches(repositories[selectedRepository].fullName, branchesPage);
                        }
                      },
                      style: { maxHeight: 300 },
                    },
                  }}
                >
                  {branches.map((branch: string, index: number) => (
                    <MenuItem key={index} value={index}>
                      {branch}
                    </MenuItem>
                  ))}
                </Select>
                <br />
              </FormControl>
            )}

            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <LoadingButton
                loading={loading}
                size="large"
                variant="contained"
                onClick={handleAddRepository}
                color="blarSuccess"
                disabled={selectedRepository === -1 || selectedBranch === -1}
              >
                Save
              </LoadingButton>
            </Box>
          </>
        ) : (
          <Box
            sx={{ display: "flex", alignItems: "center", marginTop: "20px" }}
          >
            <Typography variant="body1">
              Please connect your Github account to add a repository
            </Typography>
          </Box>
        )}
      </Box>
    </BaseModal>
  );
};

export default AddRepoModal;
