import { useEffect, useState, useContext } from "react";
import { list_integrations } from "../../../../services/Blar/Integrations";
import { IntegrationPaginatedResponse } from "./types";
import { Context } from "../../../../ContextProvider";

const useIntegrationSources = (): string[] => {
  const [sources, setSources] = useState<string[]>([]);
  const { showMessage } = useContext(Context);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    async function fetchIntegrationData() {
      try {
        setLoading(true);
        const integrationsResult = (await list_integrations())
          .data as IntegrationPaginatedResponse;

        // Extract sources from the result
        const integrationSources = integrationsResult.results.map(
          (integration) => integration.source
        );

        
        setSources(integrationSources); // Update state with sources
      } catch (error) {
        showMessage("error", `Failed to fetch integrations`);
      } finally {
        setLoading(false);
      }
    }
    fetchIntegrationData();
  }, [showMessage]);

  return sources; // Return the sources array
};

export default useIntegrationSources;
