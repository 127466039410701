/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useRef, useState } from "react";
import { Box, LinearProgress, PaletteColor, Typography } from "@mui/material";
import CustomToggleButton from "@/components/Buttons/ToggleButton";
import FilterListIcon from "@mui/icons-material/FilterList";
import AnchorMenu from "@/components/AnchorMenu/AnchorMenu";
import FilterMenu from "./FilterMenu";
import SearchBar from "@/components/SearchBar/SearchBar";
import InfiniteScroll from "react-infinite-scroll-component";
import MessageCard from "./MessageCard";
import { list_chat_rooms } from "@/services/Blar/Agents";
import { AxiosResponse } from "axios";
import { ChatsRoomResponse, ListChatRoomFilters } from "@/interfaces/IChatRoom";
import { Context } from "@/ContextProvider";
import { useNavigate } from "react-router-dom";

interface InboxProps {
  onChatRoomChange: (room: number) => void;
  filters: ListChatRoomFilters;
  setFilters: (filters: ListChatRoomFilters) => void;
  refreshChatRoomsTimestamp: number;
  setRefreshChatRoomsTimestamp: (timestamp: number) => void;
  handleClickNewChat: (event: React.MouseEvent<HTMLElement>, value: any) => void
}

const Inbox: React.FC<InboxProps> = ({
  onChatRoomChange,
  filters,
  setFilters,
  refreshChatRoomsTimestamp,
  setRefreshChatRoomsTimestamp,
  handleClickNewChat,
}) => {
  const { showMessage } = useContext(Context);
  const [filterAnchorEl, setFilterAnchorEl] = useState<null | HTMLElement>(
    null
  );
  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [offset, setOffset] = useState(0);
  const [data, setData] = useState<ChatsRoomResponse[]>([]);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const inboxContainerRef = useRef<HTMLDivElement | null>(null);

  const limit = 10;

  const handleCardClick = (id: number) => {
    onChatRoomChange(id);
    data.filter((room) => room.id === id)[0].has_unread_messages = false;
  };

  const mergeUniqueById = (
    oldData: ChatsRoomResponse[],
    newData: ChatsRoomResponse[]
  ) => {
    const mergedData = [...oldData, ...newData];
    const uniqueData = mergedData.reduce(
      (acc: ChatsRoomResponse[], current) => {
        const x = acc.find((item) => item.id === current.id);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      },
      []
    );
    return uniqueData;
  };

  const fetchNextData = async () => {
    setLoading(true);
    try {
      const response: AxiosResponse = await list_chat_rooms(
        {
          limit,
          offset,
        },
        filters
      );
      const newData = response.data.results as ChatsRoomResponse[];

      setData((prevData) => mergeUniqueById(prevData, newData));
      setHasMore(response.data.next !== null);
      setOffset((prevOffset) => prevOffset + limit);
    } catch (error) {
      showMessage("error", "Failed to get manual agents");
    } finally {
      setLoading(false);
    }
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      setData([]);
      const response: AxiosResponse = await list_chat_rooms(
        {
          limit,
          offset: 0,
        },
        filters
      );
      const newData = response.data.results;
      setData(newData);
      setHasMore(response.data.next !== null);
      setOffset(response.data.offset + limit);
    } catch (error) {
      showMessage("error", "Failed to fetch Chat Rooms");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      fetchData();
    }, 500);

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, refreshChatRoomsTimestamp]);

  const handleSearchBarChange = (value: string) => {
    setFilters({ ...filters, name: value });
  };

  const handleClickFilter = (
    event: React.MouseEvent<HTMLElement>,
    value: any
  ) => {
    setFilterAnchorEl(event.currentTarget);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      sx={{
        background: (theme) =>
          (theme.palette.secondaryBackground as PaletteColor).main,
      }}
      padding={2}
      ref={inboxContainerRef}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        mb={2}
      >
        <Typography variant="h6" sx={{ textAlign: "left" }}>
          Inbox
        </Typography>
        <Box>
          {filters.trigger_type === "codeerror" && (
            <>
              <CustomToggleButton
                value="filter"
                selected={Boolean(filterAnchorEl)}
                handleClick={handleClickFilter}
                sx={{ marginRight: 1 }}
              >
                <FilterListIcon />
              </CustomToggleButton>
              <AnchorMenu
                title="Filters"
                anchorEl={filterAnchorEl}
                setAnchorEl={setFilterAnchorEl}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                sx={{ marginTop: 1 }}
              >
                <FilterMenu
                  setFilterAnchorEl={setFilterAnchorEl}
                  filters={filters}
                  setFilters={setFilters}
                ></FilterMenu>
              </AnchorMenu>
            </>
          )}
          <CustomToggleButton
            id="new-chat"
            value="new"
            selected={false}
            handleClick={handleClickNewChat}
          >
            <img src="/icons/edit_square.svg" alt="Logo" />
          </CustomToggleButton>
        </Box>
      </Box>
      <Box mb={1} padding={1}>
        <SearchBar onChange={handleSearchBarChange} placeholder="Search..." />
      </Box>
      <Box
        id="scrollableDiv"
        padding={1}
        sx={{ flexGrow: 1, overflow: "auto", height: "100%" }}
      >
        {loading && !data.length ? (
          <LinearProgress />
        ) : (
          <InfiniteScroll
            style={{ overflow: "hidden" }}
            dataLength={data.length}
            next={fetchNextData}
            hasMore={hasMore}
            loader={<LinearProgress />}
            endMessage={<Typography variant="body2">No more data</Typography>}
            scrollableTarget="scrollableDiv"
          >
            {data.map((row) => (
              <Box mb={2} key={row.id}>
                <MessageCard
                  data={row}
                  onClick={() => handleCardClick(row.id)}
                  selected={false}
                  setRefreshChatRoomsTimestamp={setRefreshChatRoomsTimestamp}
                />
              </Box>
            ))}
          </InfiniteScroll>
        )}
      </Box>
    </Box>
  );
};

export default Inbox;
