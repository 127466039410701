import { AssignedTo, TriggerType } from "@/interfaces/IChatRoom";

export interface FileNode {
  node_id: string;
  name: string;
  type: string[];
  children?: FileNode[];
  path: string;
  object_path?: number[];
  fetched?: boolean;
}

export interface ChatRoomResponse {
  id: number;
  trigger_type: TriggerType;
  trigger_id: string | number;
  trigger: {
    email?: string;
    error_id?: string;
    source?: string;
    priority?: "low" | "medium" | "high" | "critical";
    state?: "open" | "acknowledged" | "closed";
    assigned_to?: AssignedTo;
  };
  messages: Message[];
  company_name: string;
  agent: "debugger" | "optimizer" | "cyber_security";
  name: string;
  task_state: string;
  created_at: string;
}

export interface ChatRoom {
  id: number;
  name: string;
  agent: Agent;
}

export type MessageUser = {
  first_name: string;
  last_name: string;
  email: string;
  color: string;
  secondary_color: string;
};

export type Message = {
  id?: number;
  created_at: string;
  updated_at?: string;
  message: string;
  is_blar: boolean;
  chat_room_id?: number;
  user: MessageUser;
  user_thumbs_up?: boolean;
  user_thumbs_down?: boolean;
};

export enum Agent {
  Debugger = "debugger",
  Optimizer = "optimizer",
  Cyber_Security = "cyber_security",
  General = "general",
}

export enum AgentStepType {
  User = "user",
  AIM = "aim",
  Function = "fm",
}

export interface AgentStep {
  type: AgentStepType | "info";
  chat_room: number;
  message?: Message;
  step: number;
  metadata: any;
  node_id: string;
  response?: any;
  call?: string;
  call_params?: any;
  content?: string;
}
export type States = "open" | "acknowledged" | "closed";
export type Priorities = "low" | "medium" | "high" | "critical";

export interface CodeErrorPut {
  id: number | string;
  state?: States;
  priority?: Priorities;
  assigned_to?: number;
}

export interface InputTag {
  id: number;
  value: string;
  email: string;
  first_name?: string | null;
  last_name?: string | null;
  prefix: string;
}
