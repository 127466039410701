import React, { useEffect, useRef, useState } from "react";

import { AlertColor } from "@mui/material";
import { AlertMessage } from "./interfaces/IAlertMessage";
import Cookies from "universal-cookie";
import { ICompany } from "./interfaces/ICompany";
import SideAlert from "./components/Alerts/alerts";
import axios from "axios";
import { checkSyncingRepos } from "./services/Blar/Repo_graph";
import { jwtDecode } from "jwt-decode";

export interface ContextProps {
  showMessage: (type: AlertColor, message: string) => void;
  access: string;
  refresh: string;
  hideError: () => void;
  signIn: (access: string, refresh: string) => Promise<void>;
  refreshAccessToken: () => Promise<string | null>;
  signOut: () => void;
  isUserSignIn: boolean;
  isActive: boolean;
  isOnboarding: boolean;
  isGuest: boolean;
  companyId: string | null;
  companyName: string | null;
  companyTier: number | null;
  setIsOnboarding: (value: boolean) => void;
  initialOnboardingStep: number | null;
  loadingSession: boolean;
}

export const Context = React.createContext<ContextProps>({
  showMessage: () => { },
  hideError: () => { },
  signIn: async () => { },
  signOut: () => { },
  companyId: null,
  companyName: null,
  companyTier: null,
  isUserSignIn: false,
  isActive: false,
  refresh: "",
  access: "",
  isGuest: false,
  isOnboarding: false,
  setIsOnboarding: () => { },
  refreshAccessToken: async () => null,
  initialOnboardingStep: null,
  loadingSession: true,
});

interface ContextProviderProps {
  children: React.ReactNode;
}

export const ContextProvider: React.FC<ContextProviderProps> = ({
  children,
}) => {
  const cookies = new Cookies();
  const access = cookies.get("access");
  const refresh = cookies.get("refresh");
  let firstDecodedToken: any = null;
  let firstCompany: any = null;
  if (access) {
    firstDecodedToken = jwtDecode(access);
    if (firstDecodedToken.company) {
      firstCompany = firstDecodedToken.company;
    }
  }

  const [alertMessage, setAlertMessage] = useState<AlertMessage | null>(null);
  const [isUserSignIn, setUserSignIn] = useState<boolean>(
    access ? true : false
  );
  const [isOnboarding, setIsOnboarding] = useState<boolean>(false);
  const [isActive, setIsActive] = useState<boolean>(
    firstCompany ? firstCompany.active : false
  );
  const [isGuest, setIsGuest] = useState<boolean>(
    firstCompany ? firstCompany : false
  );
  const [initialOnboardingStep, setOnboardingStep] = useState<number | null>(
    null
  );
  const [companyId, setCompanyId] = useState<string | null>(null);
  const [companyName, setCompanyName] = useState<string | null>(null);
  const [loadingSession, setLoadingSession] = useState<boolean>(true);
  const [companyTier, setCompanyTier] = useState<number | null>(
    firstCompany ? firstCompany.tier : null
  ); // Initialize companyTier

  const setCompanyInfo = (decodedToken: any) => {
    if (decodedToken.company) {
      try {
        const company = decodedToken.company as ICompany;
        setCompanyId(company.id);
        setCompanyName(company.name);
        setIsActive(company.active);
        setIsGuest(decodedToken.is_guest);
        setCompanyTier(decodedToken.company.tier);
        if (!company.finished_onboarding) {
          setIsOnboarding(true);
          setOnboardingStep(company.onboarding_step);
        } else {
          setIsOnboarding(false);
          setOnboardingStep(0);
        }
      } catch (error) {
        showMessage("error", "Error getting company info, contact support");
        signOut();
      }
    }
  };

  useEffect(() => {

    if (!isUserSignIn) {
      cookies.remove("access");
      cookies.remove("refresh");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserSignIn]);

  const setSession = (accessToken: string) => {
    // Decode JWT access
    setUserSignIn(!!accessToken);
    const decodedToken: any = jwtDecode(accessToken);
    setCompanyInfo(decodedToken);
  };

  useEffect(() => {
    const checkSession = async () => {
      setLoadingSession(true);
      const access = cookies.get("access");
      const isLogin = window.location.href.includes("/login");
      const isOnboarding = window.location.href.includes("/onboarding");
      const isInvitation = window.location.href.includes("/invitation");
      const isRedirect = window.location.href.includes("/redirect");
      if (!access) {
        if (!isLogin && !isOnboarding && !isInvitation && !isRedirect) {
          signOut();
          setLoadingSession(false);
          return;
        }
      }
      if (access) {
        setSession(access);
      }
      setLoadingSession(false);
    };
    checkSession();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const signIn = async (access: string, refresh: string) => {
    cookies.set("access", access, { secure: true, path: "/" });
    cookies.set("refresh", refresh, { secure: true, path: "/" });
    setSession(access);
  };

  const signOut = () => {
    Object.keys(cookies.getAll()).forEach(function (cookieName) {
      cookies.remove(cookieName);
    });
    setUserSignIn(false);
    setIsOnboarding(false);
    setIsActive(false);
    window.location.href = "/login"; // Add redirection to login
  };

  const showMessage = (type: AlertColor, message: string) => {
    setAlertMessage({ type, message });
  };

  const hideError = () => {
    setAlertMessage(null);
  };
  const refreshAccessToken = async (): Promise<string | null> => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/refresh/`,
        {
          refresh,
        }
      );
      const newAccessToken = response.data.access;
      cookies.set("access", newAccessToken, { path: "/", secure: true });
      setSession(newAccessToken);
      return newAccessToken;
    } catch (error) {
      console.error("Failed to refresh access token:", error);
      signOut();
      return null;
    }
  };

  return (
    <Context.Provider
      value={{
        access,
        refresh,
        showMessage,
        hideError,
        signIn,
        signOut,
        refreshAccessToken,
        loadingSession,
        isUserSignIn,
        isOnboarding,
        isActive,
        isGuest,
        companyId,
        companyName,
        companyTier,
        setIsOnboarding,
        initialOnboardingStep,
      }}
    >
      {children}
      {alertMessage && (
        <SideAlert
          severity={alertMessage.type}
          open={!!alertMessage.message}
          message={alertMessage.message}
          onClose={hideError}
        />
      )}
    </Context.Provider>
  );
};
