import "./App.css";

import { Box, CircularProgress } from "@mui/material";
import { Context, ContextProvider } from "./ContextProvider";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useContext, useEffect, useState } from "react";

import AuthForm from "./pages/Auth/AuthForm";
import AuthInvitationForm from "./pages/Auth/AuthInvitationForm";
import Chat from "./pages/Chat/Chat";
import CssBaseline from "@mui/material/CssBaseline";
import GeneralTheme from "./Theme";
import { Graph } from "./pages/Graph/Graph";
import { NotificationsContextProvider } from "./NotificationContextProvider";
import Onboarding from "./pages/Auth/Onboarding";
import Protected from "./ProtectedRoute";
import QueriesTable from "./pages/Queries";
import Redirect from "./pages/Redirect/Redirect";
import Settings from "./pages/Settings/Settings";
import Subscribe from "./pages/Subscribe/Subscribe";
import { blarClient } from "./services/Blar/Client";

const theme = createTheme(GeneralTheme);

function AppLogic() {
  const {
    isUserSignIn,
    isOnboarding,
    isActive,
    isGuest,
    signOut,
    refreshAccessToken,
    access,
    refresh
  } = useContext(Context);
  const [loadingRefresh, setLoadingRefresh] = useState(true);

  useEffect(() => {
    blarClient.init(signOut);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserSignIn]);

  useEffect(() => {
    const refreshToken = async () => {
      setLoadingRefresh(true);
      try {
        if (isUserSignIn) {
          await refreshAccessToken();
        }
      } catch (error) {
        console.error("Failed to refresh access token:", error);
        signOut();
      }
      setLoadingRefresh(false);
    };
    refreshToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const get_redirect_uri = () => {
    let getRedirectUri;
    if (!isUserSignIn) getRedirectUri = "/login";
    if (isOnboarding) getRedirectUri = "/onboarding";
    if (!isActive && isUserSignIn && !isOnboarding)
      getRedirectUri = "/subscribe";
    if (isUserSignIn && !isOnboarding && isActive) getRedirectUri = "/chat";
    return getRedirectUri;
  };

  const isAllowed = () => {
    return isUserSignIn && !isOnboarding && isActive;
  };

  const isAllowedNotGuest = () => {
    return isUserSignIn && !isOnboarding && isActive && !isGuest;
  };

  if (loadingRefresh)
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <CircularProgress />
      </Box>
    );

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/login" element={<AuthForm />} />
          <Route path="/invitation" element={<AuthInvitationForm />} />
          <Route path="/onboarding" element={<Onboarding />} />
          <Route
            path="/graph"
            element={
              <Protected
                isAllowed={isAllowed()}
                redirectTo={get_redirect_uri()}
              >
                <Graph />
              </Protected>
            }
          />
          <Route
            path="/chat/:chatRoomId?"
            element={
              <Protected
                isAllowed={isAllowed()}
                redirectTo={get_redirect_uri()}
              >
                <NotificationsContextProvider access={access} refresh={refresh}>
                  <Chat />
                </NotificationsContextProvider>
              </Protected>
            }
          />
          <Route
            path="/settings"
            element={
              <Protected
                isAllowed={isAllowedNotGuest()}
                redirectTo={get_redirect_uri()}
              >
                <Settings />
              </Protected>
            }
          />
          <Route
            path="/queries"
            element={
              <Protected
                isAllowed={isAllowedNotGuest()}
                redirectTo={get_redirect_uri()}
              >
                <QueriesTable />
              </Protected>
            }
          />
          <Route
            path="/subscribe"
            element={
              <Protected
                isAllowed={isUserSignIn && !isOnboarding}
                redirectTo={get_redirect_uri()}
              >
                <Subscribe />
              </Protected>
            }
          />
          <Route
            path="/redirect"
            element={
              <Protected
                isAllowed={isUserSignIn}
                redirectTo={get_redirect_uri()}
              >
                <Redirect />
              </Protected>
            }
          />
          <Route path="*" element={<Navigate to="/chat" replace />} />
        </Routes>
      </div>
    </Router>
  );
}

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ContextProvider>
        <AppLogic />
      </ContextProvider>
    </ThemeProvider>
  );
};

export default App;
