import React, { useContext, useEffect } from "react";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import TuneIcon from "@mui/icons-material/Tune";
import { useLocation, useNavigate } from "react-router-dom";
import { Stack } from "@mui/material";
import CustomToggleButton from "../Buttons/ToggleButton";
import BubbleChartOutlinedIcon from "@mui/icons-material/BubbleChartOutlined";
import InboxOutlinedIcon from "@mui/icons-material/InboxOutlined";
import AnchorMenu from "../AnchorMenu/AnchorMenu";
import MoreMenu from "./MoreMenu";
import { useTheme } from "@mui/material/styles";
import { Context } from "@/ContextProvider";
import useIntegrationSources from "@/pages/Settings/components/Integrations/IntegrationsList" ;

type SidebarProps = {
  style?: React.CSSProperties;
};

function getInitialSelected(location: any) {
  const queryParams = new URLSearchParams(location.search);
  const triggerType = queryParams.get("triggerType");
  if (location.pathname === "/graph") return "graph";
  if (location.pathname === "/chat") {
    return triggerType || "";
  }
  if (location.pathname === "/settings" || location.pathname === "/queries")
    return "settings";
  return "";
}

const Sidebar: React.FC<SidebarProps> = ({ style }) => {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const { isGuest } = useContext(Context);
  const sources = useIntegrationSources(); 
  const [selected, setSelected] = React.useState(getInitialSelected(location));
  const [settingsAnchorEl, setSettingsAnchorEl] =
    React.useState<null | HTMLElement>(null);

  useEffect(() => {
    setSelected(getInitialSelected(location));
  }, [location]);

  const handleClickFilters = (
    event: React.MouseEvent<HTMLElement>,
    value: string // Assuming value is a string
  ) => {
    setSelected(value);
    if (value === "") {
      navigate("/chat");
      return;
    }
    navigate(`/chat?triggerType=${encodeURIComponent(value)}`);
  };

  const handleClickGraph = (
    event: React.MouseEvent<HTMLElement>,
    value: any
  ) => {
    setSelected(value);
    navigate("/graph");
  };

  const handleClickSettingsMenu = (
    event: React.MouseEvent<HTMLElement>,
    value: any
  ) => {
    setSettingsAnchorEl(event.currentTarget);
  };

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: 50,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: 50,
          boxSizing: "border-box",
          borderRadius: 0,
          backgroundColor: "blarBlack",
          borderRight: "1px solid",
          borderColor: theme.palette.divider,
        },
        ...style,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: 50,
        }}
      >
        <Box
          component="img"
          src="/logos/BlarIconWhite.svg"
          alt="Logo"
          sx={{ height: 25, cursor: "pointer" }}
          onClick={() => navigate("/chat")}
        />
      </Box>

      <Box
        mt={4}
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          marginTop: "20px",
          flexGrow: 1,
        }}
      >
        <Stack
          spacing={2}
          direction="column"
          useFlexGap
          flexWrap="wrap"
          alignItems="center"
        >
          <CustomToggleButton
            value=""
            selected={selected === ""}
            handleClick={handleClickFilters}
            tooltip="All the chats assign to you"
          >
            <InboxOutlinedIcon
              sx={{ width: 20, height: 20, color: "primary" }}
            />
          </CustomToggleButton>
          {!isGuest && (
            <CustomToggleButton
              value="sentry"
              selected={selected === "sentry"}
              handleClick={handleClickFilters}
              tooltip="All the chats made by Sentry"
              hidden={ !sources.includes("sentry") }
            >
              <img
                src="/integrations/SentryIcon.svg"
                alt="Logo"
                style={{ width: 17, height: 15 }}
              />
            </CustomToggleButton>
          )}
          <CustomToggleButton
            value="baseuser"
            selected={selected === "baseuser"}
            handleClick={handleClickFilters}
            tooltip="All the chats made by you"
  
          >
            <AccountCircleOutlinedIcon
              sx={{ width: 20, height: 20, color: "primary" }}
            />
          </CustomToggleButton>
        </Stack>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          mb: 1,
        }}
      >
        <Stack
          spacing={2}
          direction="column"
          useFlexGap
          flexWrap="wrap"
          alignItems="center"
        >
          {process.env.REACT_APP_API_URL!.includes("stg-api") && (
            <CustomToggleButton
              value="graph"
              selected={selected === "graph"}
              handleClick={handleClickGraph}
            >
              <BubbleChartOutlinedIcon
                sx={{ width: 20, height: 20, color: "primary" }}
              />
            </CustomToggleButton>
          )}
          <CustomToggleButton
            value="settings"
            selected={Boolean(settingsAnchorEl) || selected === "settings"}
            handleClick={handleClickSettingsMenu}
          >
            <TuneIcon sx={{ width: 20, height: 20, color: "primary" }} />
          </CustomToggleButton>
        </Stack>
      </Box>
      <AnchorMenu
        title="More"
        anchorEl={settingsAnchorEl}
        setAnchorEl={setSettingsAnchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        sx={{ width: 200, marginLeft: 2 }}
      >
        <MoreMenu setSettingsAnchorEl={setSettingsAnchorEl} />
      </AnchorMenu>
    </Drawer>
  );
};

export default Sidebar;
