import { Grid, IconButton, Typography } from "@mui/material";
import { delete_repo, list_repos } from "@/services/Blar/Repo_graph";
import { useContext, useEffect, useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import AddRepoModal from "./components/AddRepoComponent";
import { Context } from "../../../../ContextProvider";
import { NotificationsContextProvider } from "@/NotificationContextProvider";
import { RepoPaginatedResponse } from "./types";
import { TableRowData } from "@/components/Tables/types";
import TableWithActions from "@/components/Tables/TableWithActions";
import { convertReposToRow } from "./helper";

const defaultReposTitles = ["Name", "GitHub Url", "Main Branch", "Last Sync"];

const ReposTable = () => {
  const [reposRow, setReposRow] = useState<TableRowData[]>([]);
  const [openAddRepo, setopenAddRepo] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const [createdObject, setCreatedObject] = useState(Date.now());
  const { showMessage, access, refresh } = useContext(Context);

  useEffect(() => {
    async function fetchReposData() {
      try {
        setLoading(true);
        const reposResult = (await list_repos()).data as RepoPaginatedResponse;
        const repoRows = convertReposToRow(reposResult);
        setReposRow(repoRows);
      } catch (error) {
        showMessage("error", `Failed to fetch repos`);
      } finally {
        setLoading(false);
      }
    }

    fetchReposData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createdObject]);

  const handlRepoDelete = async (index: number) => {
    try {
      await delete_repo(reposRow[index]._id.content as string);
      setCreatedObject(Date.now());
      showMessage("success", "Repo deleted successfully");
    } catch (error) {
      showMessage("error", "Error deleting Repo");
    }
  };
  const handleAddRepo = () => {
    setopenAddRepo(true);
  };

  return (
    <>
      <Grid item xs={12}>
        <Grid container alignItems="center" sx={{ marginBottom: "20px" }}>
          <Grid item>
            <Typography variant="h6">Repositories</Typography>
          </Grid>
          <Grid item>
            <IconButton
              sx={{
                border: "1px solid #FFFFFF4D",
                color: "gray",
                marginLeft: "10px",
              }}
              size="small"
              onClick={handleAddRepo}
            >
              <AddIcon />
            </IconButton>
          </Grid>
        </Grid>
        <NotificationsContextProvider access={access} refresh={refresh} >
          <TableWithActions
            loading={loading}
            tableRows={reposRow}
            onDelete={handlRepoDelete}
            defaultTitles={defaultReposTitles}
          />
        </NotificationsContextProvider>
      </Grid >
      <AddRepoModal
        setCreatedObject={setCreatedObject}
        open={openAddRepo}
        setOpen={setopenAddRepo}
      />
    </>
  );
};

export default ReposTable;
