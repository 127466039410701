import "./ChatInput";

import {
  Agent,
  AgentStep,
  ChatRoomResponse,
  FileNode,
  InputTag,
  Message,
  States,
} from "../types";
import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import {
  increment_thumbs_down,
  increment_thumbs_up,
} from "@/services/Blar/Chat";

import ChatPlaceHolder from "./placeHolders/ChatPlaceHolder";
import CheckIcon from "@mui/icons-material/Check";
import { Context } from "@/ContextProvider";
import CopyButton from "@/components/Buttons/CopyButton";
import FeedbackBox from "./Feedback";
import LoadingDots from "@/components/Animations/LoadingDots";
import ReactMarkdown from "react-markdown";
import RenderUserMessage from "./RenderUserMessage";
import SyntaxHighlighter from "react-syntax-highlighter";
import TagifyComponent from "./ChatInput";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import Timestamp from "@/components/Typographies/Timestamp";
import UndoIcon from "@mui/icons-material/Undo";
import UserAvatar from "@/components/User/UserAvatar";
import { tomorrowNightBright as codeStyle } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { code_error_put } from "@/services/Blar/Integrations";
import { mapMessageUserToUser } from "../helpers";
import rehypeRaw from "rehype-raw";
import useStyles from "../styles/ChatBody";
import { useTheme } from "@mui/material/styles";

interface ChatBodyProps {
  selectedChatRoom: number | null;
  chatRoomData: ChatRoomResponse | undefined;
  messages: Message[];
  setMessages: (messages: Message[]) => void;
  agentStep: AgentStep | null;
  sendMessage: (
    message: string,
    node_ids: string[],
    frontEndMessage: string | null
  ) => void;
  selectedAgent: Agent | undefined;
  selectedNode: FileNode | undefined;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  setChatRoomData: (chatRoomData: ChatRoomResponse) => void;
  setRefreshChatRoomsTimestamp: (timestamp: number) => void;
  handleSendTag: (message: string, tags: InputTag[]) => void;
  syncing: boolean;
}

const ChatBody: React.FC<ChatBodyProps> = ({
  selectedChatRoom,
  chatRoomData,
  messages,
  agentStep,
  sendMessage,
  selectedAgent,
  selectedNode,
  loading,
  setLoading,
  setChatRoomData,
  setRefreshChatRoomsTimestamp,
  handleSendTag,
  setMessages,
  syncing,
}: ChatBodyProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const { showMessage } = useContext(Context);
  const messageListEndRef = React.useRef<HTMLDivElement>(null);
  const [feedbackOpen, setFeedbackOpen] = useState(false);
  const [feedbackMessageId, setFeedbackMessageId] = React.useState<
    number | null
  >(null);

  const handleMarkAsResolved = async (state: States) => {
    if (!chatRoomData) return;

    try {
      await code_error_put({
        id: chatRoomData.trigger_id,
        state: state,
      });

      setChatRoomData({
        ...chatRoomData, // Spread the existing data
        trigger: {
          ...chatRoomData.trigger, // Spread the existing trigger data
          state, // Update the state
        },
      });

      setRefreshChatRoomsTimestamp(Date.now());
    } catch (error) {
    } finally {
    }
  };

  useEffect(() => {
    if (messageListEndRef.current) {
      messageListEndRef.current.scrollIntoView();
    }
  }, [messages, agentStep, selectedNode]);

  const getName = (message: Message) => {
    return message.user.first_name
      ? message.user.first_name + " " + message.user.last_name
      : message.user.email;
  };

  const handleThumbsUp = async (messageId: number) => {
    try {
      await increment_thumbs_up(messageId);
      const newMessages = messages.map((msg: Message) =>
        msg.id === messageId
          ? { ...msg, user_thumbs_up: true, user_thumbs_down: false }
          : msg
      );
      setMessages(newMessages);
      setFeedbackMessageId(messageId);
      setFeedbackOpen(true);

      showMessage("success", "Thanks for the feedback!");
    } catch (error) {
      showMessage("error", "Error sending feedback");
    }
  };

  const handleThumbsDown = async (messageId: number) => {
    try {
      await increment_thumbs_down(messageId);
      const newMessages = messages.map((msg: Message) =>
        msg.id === messageId
          ? { ...msg, user_thumbs_up: false, user_thumbs_down: true }
          : msg
      );
      setMessages(newMessages);
      setFeedbackMessageId(messageId);
      setFeedbackOpen(true);

      showMessage("success", "Thanks for the feedback!");
    } catch (error) {
      showMessage("error", "Error sending feedback");
    }
  };

  return (
    <Box className={classes.chatContainer}>
      <Box className={classes.messageContainer}>
        {messages.length ? (
          <List className={classes.messageList}>
            {messages.map((message, index) => (
              <ListItem
                key={message.id}
                sx={{
                  justifyContent: message.is_blar ? "flex-start" : "flex-end",
                  alignItems: "flex-start",
                }}
              >
                {message.is_blar && (
                  <img
                    src="/logos/GreyLogo.svg"
                    alt="Blar"
                    className={classes.avatar}
                  />
                )}

                <Paper
                  className={classes.message}
                  sx={{
                    backgroundColor: message.is_blar
                      ? theme.palette.background.paper
                      : theme.palette.divider,
                    border: "1px solid",
                    borderColor: theme.palette.divider,
                  }}
                >
                  <Typography
                    textAlign={"start"}
                    variant="body2"
                    sx={{ fontStyle: "italic" }}
                  >
                    {message.is_blar ? "Blar" : getName(message)}
                  </Typography>
                  <ListItemText
                    sx={{
                      textOverflow: "ellipsis",
                      wordBreak: "break-word",
                    }}
                    primary={
                      message.is_blar ? (
                        <ReactMarkdown
                          rehypePlugins={[rehypeRaw]}
                          children={message.message}
                          components={{
                            code(props) {
                              const { children, className, ...rest } = props;
                              const match = /language-(\w+)/.exec(
                                className || ""
                              );
                              return match ? (
                                <div style={{ position: "relative" }}>
                                  <SyntaxHighlighter
                                    children={String(children).replace(
                                      /\n$/,
                                      ""
                                    )}
                                    language={match[1]}
                                    style={codeStyle}
                                    customStyle={{
                                      borderRadius: "20px",
                                      padding: "20px",
                                      fontSize: "14px",
                                    }}
                                  />
                                  <Box className={classes.copyButton}>
                                    <CopyButton
                                      copyValue={String(children).replace(
                                        /\n$/,
                                        ""
                                      )}
                                      backgroundColor="black"
                                      size="small"
                                      sx={{
                                        height: "20px",
                                      }}
                                    />
                                  </Box>
                                </div>
                              ) : (
                                <code
                                  {...rest}
                                  className={className}
                                  style={{
                                    textOverflow: "ellipsis",
                                    wordBreak: "break-word",
                                    whiteSpace: "pre-wrap",
                                  }}
                                >
                                  {children}
                                </code>
                              );
                            },
                          }}
                        />
                      ) : (
                        <RenderUserMessage message={message.message} />
                      )
                    }
                  />
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Box display="flex" alignItems="center">
                      {message.is_blar && (
                        <>
                          <IconButton
                            size="small"
                            onClick={() => handleThumbsUp(message.id!)}
                          >
                            {message.user_thumbs_up ? (
                              <ThumbUpIcon fontSize="small" />
                            ) : (
                              <ThumbUpOffAltIcon fontSize="small" />
                            )}
                          </IconButton>
                          <IconButton
                            size="small"
                            onClick={() => handleThumbsDown(message.id!)}
                          >
                            {message.user_thumbs_down ? (
                              <ThumbDownIcon fontSize="small" />
                            ) : (
                              <ThumbDownOffAltIcon fontSize="small" />
                            )}
                          </IconButton>
                        </>
                      )}
                    </Box>
                    <Box className={classes.timestamp}>
                      <Timestamp timestamp={message.created_at} />
                    </Box>
                  </Box>
                </Paper>

                {!message.is_blar && (
                  <Box m={1}>
                    <UserAvatar user={mapMessageUserToUser(message.user)} />
                  </Box>
                )}
              </ListItem>
            ))}
            {agentStep && (
              <ListItem
                key={agentStep.step + agentStep.type}
                sx={{
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <img
                  src="/logos/GreyLogo.svg"
                  alt="Blar"
                  className={classes.avatar}
                />
                Exploring {agentStep.response.node_name} inside{" "}
                {agentStep.response.file_path.split("/").slice(-3).join("/")}
                <LoadingDots />
              </ListItem>
            )}

            {agentStep === null && loading && (
              <ListItem
                key={-1}
                sx={{
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <img
                  src="/logos/GreyLogo.svg"
                  alt="Blar"
                  className={classes.avatar}
                />
                Blar is thinking...
                <LoadingDots />
              </ListItem>
            )}
            <div ref={messageListEndRef} />

            {feedbackOpen && (
              <ListItem
                sx={{
                  justifyContent: "center", // Center the feedback box
                  alignItems: "center",
                  width: "100%", // Ensure it takes the full width of the list
                }}
              >
                <FeedbackBox
                  setFeedbackOpen={setFeedbackOpen}
                  feedbackMessageId={feedbackMessageId}
                />
              </ListItem>
            )}
          </List>
        ) : (
          <ChatPlaceHolder
            selectedAgent={selectedAgent || chatRoomData?.agent}
            selectedNode={selectedNode}
            selectedChatRoom={selectedChatRoom}
            syncing={syncing}
            loading={
              messages.length === 0 &&
              (chatRoomData?.task_state === "pending" ||
                chatRoomData?.task_state === "in_progress")
            }
          />
        )}
      </Box>
      <Box
        className={classes.bottomBarContainer}
        sx={{ display: "flex", alignItems: "center" }}
      >
        {selectedChatRoom && (messages.length || selectedNode) && (
          <Paper
            className={classes.textFieldContainer}
            sx={{
              borderColor: theme.palette.divider,
              flexGrow: 1, // Makes the Paper take up the remaining space
              display: "flex",
              alignItems: "center",
            }}
            id="message-input"
          >
            <TagifyComponent
              sendMessage={sendMessage}
              loading={loading}
              setLoading={setLoading}
              selectedChatRoom={selectedChatRoom}
              selectedAgent={selectedAgent}
              selectedNode={selectedNode}
              handleSendTag={handleSendTag}
            ></TagifyComponent>
          </Paper>
        )}
        {chatRoomData?.trigger_type === "codeerror" && (
          <>
            {chatRoomData?.trigger.state !== "closed" ? (
              <Tooltip title="Mark as resolved">
                <Box
                  ml={2}
                  padding={2}
                  sx={{
                    height: "100%", // Matches the height of the Paper
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                    backgroundColor: "#161B22",
                    borderRadius: "5px",
                  }}
                  onClick={() => handleMarkAsResolved("closed")}
                >
                  <CheckIcon />
                </Box>
              </Tooltip>
            ) : (
              <Tooltip title="Mark as unresolved">
                <Box
                  ml={2}
                  padding={2}
                  sx={{
                    height: "100%", // Matches the height of the Paper
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                    backgroundColor: "#161B22",
                    borderRadius: "5px",
                  }}
                  onClick={() => handleMarkAsResolved("open")}
                >
                  <UndoIcon />
                </Box>
              </Tooltip>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default ChatBody;
