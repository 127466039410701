import {
  AgentStep,
  ChatRoom,
  ChatRoomResponse,
  InputTag,
} from "@/pages/Chat/components/types";
import { blarClient } from "./Client";
import { AxiosResponse } from "axios";

export interface CreateChatRoomParams {
  node_ids: string[];
  agent: string;
}

export interface SendMessageParams {
  message: string;
  node_ids?: string[];
  front_end_message: string | null;
  type: "blar_agent_message";
}

export interface SendTagParams {
  message: string;
  tags: InputTag[];
  type: "tag_message";
}

interface MessageTag {
  id: number;
  value: string;
  email: string;
  first_name: string;
  last_name: string;
  prefix: string;
  __isValid: boolean;
  __tagId: string;
}
export interface MessageTagParams {
  chat_room_id: number;
  Tag: MessageTag[];
}

export async function send_message(params: CreateChatRoomParams) {
  return await blarClient.request("post", `/agents/chat/`, params);
}

export async function post_message_tag(data: MessageTagParams) {
  return await blarClient.request("post", `/agents/message-tag`, data);
}

export async function get_chat_room(
  chatRoomId: number
): Promise<AxiosResponse<ChatRoom>> {
  return blarClient.request("get", `/agents/chat/${chatRoomId}`);
}

export async function get_chat_room_messages(
  chatRoomId: number
): Promise<ChatRoomResponse> {
  return (await blarClient.request("get", `/agents/chat/${chatRoomId}`))
    .data as ChatRoomResponse;
}

export async function mark_as_read(chatRoomId: number) {
  return await blarClient.request("patch", `/agents/mark-chat-as-read`, {
    chat_room_id: chatRoomId,
  });
}

export function process_message_chunk(value: any): AgentStep {
  return JSON.parse(value);
}

export async function increment_thumbs_up(messageId: number) {
  const response = await blarClient.request(
    "post",
    `/agents/message/${messageId}/thumbs-up/`
  );
  return response.data;
}

export async function increment_thumbs_down(messageId: number) {
  const response = await blarClient.request(
    "post",
    `/agents/message/${messageId}/thumbs-down/`
  );
  return response.data;
}

export async function submit_feedback(messageId: number, feedbackText: string) {
  const response = await blarClient.request(
    "post",
    `/agents/message/${messageId}/submit-feedback/`,
    { feedback_text: feedbackText }
  );
  return response.data;
}
